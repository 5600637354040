document.addEventListener('DOMContentLoaded', function () {
    (function (window, document) {

        const emailLinks = document.querySelectorAll('.link-encode');

        emailLinks.forEach(link => {
            let emailRevealed = false; // Indique si l'e-mail a été révélé pour cet élément
            const encodedEmail = link.getAttribute('data-encode'); // E-mail encodé en base64
            const decodedEmail = atob(encodedEmail); // Décoder l'e-mail

            link.addEventListener('click', function () {
                // Display
                if (!emailRevealed) {

                    link.innerHTML = `
                        <span>${decodedEmail}</span>
                    `;

                    // Auto Copy-paste
                    navigator.clipboard.writeText(decodedEmail).then(() => {
                        console.log('E-mail copié dans le presse-papiers : ' + decodedEmail);
                    }).catch(err => {
                        console.error('Échec de la copie : ', err);
                    });

                    link.classList.remove('btn-outline-secondary');
                    link.classList.add('btn-secondary');

                    emailRevealed = true;

                // Classical mailto
                } else {
                    window.location.href = `mailto:${decodedEmail}`;

                    // Auto Copy-paste
                    navigator.clipboard.writeText(decodedEmail).then(() => {
                        console.log('E-mail copié dans le presse-papiers : ' + decodedEmail);
                    }).catch(err => {
                        console.error('Échec de la copie : ', err);
                    });
                }
            });
        });
    })(window, document);
});
