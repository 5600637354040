export function scrollDirection() {

    return {
        isScrollingUp: true,
        isScrollingDown: false,
        lastScrollTop: 0,

        handleScroll() {
          const scrollToTop = window.pageYOffset || document.documentElement.scrollTop;

          if (scrollToTop == this.lastScrollTop) {
            this.isScrollingUp = false;
            this.isScrollingDown = false;
          }
          if (scrollToTop < this.lastScrollTop) {
            this.isScrollingUp = true;
            this.isScrollingDown = false;
          }
          if (scrollToTop > this.lastScrollTop) {
            this.isScrollingUp = false;
            this.isScrollingDown = true;
          }

          this.lastScrollTop = scrollToTop;
        },
    };
}
