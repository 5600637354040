
// Alpine - libs

import Alpine from 'alpinejs';
import { scrollDirection } from './vanilla-scrollDirection';
import intersect from '@alpinejs/intersect'
//import Tooltip from "@ryangjchandler/alpine-tooltip";
//import Mousetrap from '@danharrin/alpine-mousetrap';

Alpine.data('scrollDirection', scrollDirection);

//Alpine.plugin(Tooltip);
//Alpine.plugin(Mousetrap);
Alpine.plugin(intersect);

// Init
window.Alpine = Alpine;
window.intersect = intersect;

