
// Bootstrap 5.2 components

//import * as Popper from "@popperjs/core"
//import { Collapse } from 'bootstrap';
//import { Carousel } from 'bootstrap';
import { Modal } from 'bootstrap';
import { Offcanvas } from 'bootstrap';
//import { Tab } from 'bootstrap';

window.Modal = Modal;
window.Offcanvas = Offcanvas;

/*
export { default as Alert } from './src/alert.js'
export { default as Button } from './src/button.js'
export { default as Carousel } from './src/carousel.js'
export { default as Collapse } from './src/collapse.js'
export { default as Dropdown } from './src/dropdown.js'
export { default as Modal } from './src/modal.js'
export { default as Offcanvas } from './src/offcanvas.js'
export { default as Popover } from './src/popover.js'
export { default as ScrollSpy } from './src/scrollspy.js'
export { default as Tab } from './src/tab.js'
export { default as Toast } from './src/toast.js'
export { default as Tooltip } from './src/tooltip.js'
*/

// Enable Popover
//const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
//const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))


// Remove Aria focus
document.addEventListener("DOMContentLoaded", function () {
    document.addEventListener('hide.bs.modal', function (event) {
        if (document.activeElement) {
            document.activeElement.blur();
        }
    });
});
