//
// Signature Animate
//

import Vivus from 'vivus';

document.addEventListener('DOMContentLoaded', function() {
    (function (window, document) {

        this.FlashVivus = new Vivus('signature',{duration: 80}).play();

    })(window, document);
});
