/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap';

import "./framework-bootstrap"; // Modal, Carousel, Placeholder
import './framework-alpine'; // Intersect, Mousetrap
//import AOS from "aos"; // no trigger correctly with large height of section - Good with little element

import "./vanilla-device-bodyclass";
import "./vanilla-data-href";
import "./decode-mail";

// Datas

// Alpine.data('searchFilters', searchFilters);
// Alpine.data('favorites', favorites);
// Alpine.data('propertyBook', propertyBook);
// Alpine.data('rateDisplay', rateDisplay);


Alpine.start();
